import _default from './default';
import horiz from './horiz';
import ikegami from './ikegami';
import jround from './jround';

export default {
  default: _default,
  horiz,
  ikegami,
  jround,
};
