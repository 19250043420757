import * as React from "react";
const SvgIChevronUpCircle = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "20 20 480 480", width: "1em", height: "1em", className: "g-icon", ...props }, /* @__PURE__ */ React.createElement("polyline", { points: "352 296 256 200 160 296", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 32
} }), /* @__PURE__ */ React.createElement("path", { d: "M256,64C150,64,64,150,64,256s86,192,192,192,192-86,192-192S362,64,256,64Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10,
  strokeWidth: 32
} }));
export default SvgIChevronUpCircle;
