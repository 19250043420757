import * as React from "react";
const SvgIAdd = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", width: "1em", height: "1em", className: "g-icon", ...props }, /* @__PURE__ */ React.createElement("line", { x1: 256, y1: 112, x2: 256, y2: 400, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 32
} }), /* @__PURE__ */ React.createElement("line", { x1: 400, y1: 256, x2: 112, y2: 256, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 32
} }));
export default SvgIAdd;
